
import portfolio01 from '../assets/images/portfolio/portfolio-01.jpg';
import portfolio02 from '../assets/images/portfolio/portfolio-02.jpg';
import portfolio03 from '../assets/images/portfolio/portfolio-03.jpg';
import portfolio04 from '../assets/images/portfolio/portfolio-04.jpg';
import portfolio05 from '../assets/images/portfolio/portfolio-05.jpg';
import portfolio06 from '../assets/images/portfolio/portfolio-06.jpg';


export const contactphone = '+971553328591'
export const contactemail = 'nabil.biloo.alsaadi95@gmail.com'
export const instagramLink = "https://www.instagram.com/eng_nabil_alsaadi/"
export const linkedinLink = "https://www.linkedin.com/in/nabil-alsaadi/"
export const githubLink = "https://github.com/nabil-alsaadi"
export const whatsappLink = "https://wa.me/+971553328591"

export const SERVICE_ID = "service_m8agpm4"
export const TEMPLATE_ID = "template_g1m5cha"
export const USER_ID = "CPVqiBZSYN1Qo3SmM"

export const SERVICES = [
    {
      id: 1,
      title: 'Business Strategy',
      description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.',
      icon: 'menu',
      link: '#',
      delay: '100',
    },
    {
      id: 2,
      title: 'Mobile Development',
      description: 'It uses a dictionary of over 200 Latin words, combined with a handful of model sentence.',
      icon: 'smartphone',
      link: '#',
      delay: '300'
    },
    {
      id: 3,
      title: 'Web Development',
      description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.',
      icon: 'layout',
      link: '#',
      delay: '500'
    }
  ];
  

export const PORTFOLIO_ITEMS = [
    
  {
    id: 1,
    image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2F1.png?alt=media&token=9b2ef8f8-b0e5-4b7f-8d95-46335c36114e',
    category: 'MOBILE APPLICATION',
    likes: 600,
    title: 'SOAPY SPLASH',
    delay: 100,
    description: `The Customer App is a user-centric mobile application designed for seamless interaction and ease of use. Built with a focus on simplicity and functionality, the app ensures smooth navigation and a responsive experience across devices. Leveraging modern mobile technologies, the app provides real-time updates, secure transactions, and personalized user interactions, making it a reliable and efficient tool for customers to engage with the service.`,
    link: 'https://soapysplashuae.com',
    iosLink: 'https://apps.apple.com/gb/app/soapy-splash/id1576561103'
  },
//   {
//     id: 2,
//     image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2FScreenshot%202024-08-19%20at%202.49.52%E2%80%AFAM.png?alt=media&token=870f757f-a4df-4fad-a57d-7bd1d0bdbf06',
//     category: 'WEBSITE & ADMIN PANEL',
//     likes: 750,
//     title: 'SOAPY SPLASH WEBSITE',
//     delay: 300,
//     description: `Soapy Splash is a dynamic web app built with Angular and Firebase, delivering a responsive and seamless user experience. The real-time data management and secure authentication provided by Firebase ensure efficient performance and robust functionality.
//     The WordPress-powered landing page enhances the platform with a professional, SEO-friendly interface, designed to capture user interest and drive engagement. This combination of cutting-edge web technologies creates a powerful and intuitive online experience.`,
//     link: 'https://soapysplashuae.com'
//   },
{
    id: 19,
    image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2Fbuddyboss.png?alt=media&token=2b607976-4189-402e-8f2e-b201b695b6f3',
    category: 'Mobile APP',
    likes: 690,
    title: 'BuddyBoss App',
    delay: 500,
    description: `The BuddyBoass app, developed using React Native with AWS services and WordPress for content management, showcases my expertise in both mobile development and backend integration. I played a key role in fixing numerous issues, adding new features, and implementing atomic component design to avoid render issues. My work included deep optimization of the player for enhanced performance and extensive integration with LearnDash and other course APIs and tools. This project highlights my ability to troubleshoot, optimize, and innovate within complex systems while leveraging cloud services and content management platforms.`,
    androidLink: 'https://play.google.com/store/apps/details?id=com.buddyboss.communities&hl=en_US',
    link: 'https://www.buddyboss.com'
  },
  {
    id: 14,
    image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2Ftentime.png?alt=media&token=ef67a068-b42b-45d9-bcab-771645511cb8',
    category: 'MOBILE & APPLE TV',
    likes: 690,
    title: 'TenTime',
    delay: 500,
    description: `For the Teatime project, I played a key role in ensuring code quality through rigorous QA processes and actively contributed to the development of their Apple TV app. My involvement in this project highlights my ability to maintain high standards in code integrity while also bringing innovative ideas to the table for a seamless user experience on the Apple TV platform.`,
    iosLink: 'https://apps.apple.com/us/app/tentime/id1458611147',
    link: 'https://tentime.com'
  },
  {
    id: 24,
    image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2FScreenshot%202024-08-19%20at%206.32.27%E2%80%AFPM.png?alt=media&token=5856056f-b96e-4b79-a89d-bead34851648',
    category: 'Mobile APP',
    likes: 690,
    title: 'FARAS Module - Federal Authority of Nuclear Regulation (FANR)',
    delay: 500,
    description: `I developed the FARAS module for the Federal Authority of Nuclear Regulation (FANR), a specialized system designed to manage gifts, referrals, and rewards within the organization. This module was built using a combination of SwiftUI, Swift and Objective-C, ensuring a seamless and efficient user experience. To meet the stringent security requirements of the authority, I integrated a specialized authentication library, providing robust and secure access control. This internal application plays a critical role in enhancing employee engagement and compliance with regulatory standards, though it's not accessible externally.`,
    link: 'https://www.buddyboss.com'
  },
  
  {
    id: 20,
    image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2Fbblearndash.jpg?alt=media&token=e1d85153-d85d-4432-96e8-0504ce1fa500',
    category: 'Mobile APP',
    likes: 690,
    title: 'BuddyBoss App for LearnDash',
    delay: 500,
    description: `The BuddyBoss App for LearnDash is a powerful mobile solution designed to support course creation and teaching management. Developed for both Android and iOS, the app integrates seamlessly with WordPress, allowing educators to customize and manage their courses directly from the platform. This app provides a flexible and user-friendly environment for building and delivering educational content, making it an essential tool for modern e-learning experiences.`,
    androidLink: 'https://play.google.com/store/apps/details?id=com.buddyboss.ldlms&hl=en_US',
    link: 'https://www.buddyboss.com'
  },
  {
    id: 21,
    image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2Fnurse.png?alt=media&token=b3c44a9f-ba14-4de8-8bc2-1a89f764d211',
    category: 'Web & Mobile APP',
    likes: 690,
    title: 'NurseCon',
    delay: 500,
    description: `NurseCon, created by Nurse Blake, is the ultimate app for nurses and nursing students, providing a free platform to connect with peers and manage CNE certificates on-the-go. Built with React Native using BuddyBoss and customized to meet the unique needs of the nursing community, this app offers a seamless and convenient way to support your nursing journey, all within one powerful and user-friendly platform.`,
    link: 'https://nursecon.com',
    iosLink: 'https://apps.apple.com/us/app/nursecon/id1565528228?platform=iphone'
  },
  {
    id: 22,
    image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2Fhappy-slider-2048x840.png?alt=media&token=8396aa1a-c086-4947-bb42-1dbd454d030c',
    category: 'Web & Mobile APP',
    likes: 690,
    title: 'The Happy Pear',
    delay: 500,
    description: `I developed The Happy Pear's App using React Native to deliver a seamless experience on both iOS and Android platforms, alongside a web version built with WordPress. The app, designed for wellness enthusiasts and plant-based lifestyle advocates, features over 500 meticulously crafted plant-based recipes. Customizing the app with WordPress and the BuddyBoss theme, I ensured a cohesive and user-friendly interface across all platforms. This project showcases my expertise in cross-platform mobile development, web development, and seamless integration with content management systems.`,
    link: 'https://thehappypear.ie/',
    iosLink: 'https://apps.apple.com/nz/app/happy-pear-vegan-food-health/id1587396851',
    androidLink: 'https://play.google.com/store/apps/details?id=ie.thehappypear.thehappypearmainapp&pli=1'
  },
  {
    id: 11,
    image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2Fcarrental.png?alt=media&token=8c2582f5-31ec-4baf-b0aa-ba2484480f97',
    category: 'WEBSITE',
    likes: 690,
    title: 'Luxury Car Rental',
    delay: 500,
    description: `The Luxury Car Rental Dubai website, built using WordPress, offers users a seamless experience in booking their dream car at affordable prices. Featuring integrated booking functionality, the site allows customers to easily select and reserve the perfect luxury vehicle for any occasion. The website’s design emphasizes both elegance and user-friendliness, ensuring that every visitor can effortlessly navigate and secure their ideal car rental in Dubai.`,
    link: 'https://www.luxurycarrental.ae'
  },
  {
    id: 4,
    image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2FScreenshot%202024-08-19%20at%203.13.26%E2%80%AFAM.png?alt=media&token=00b6cfa3-2b6a-4d69-8d94-f02957a7ac8f',
    category: 'WEBSITE',
    likes: 360,
    title: 'AE HR CONSULTING',
    delay: 100,
    description: `AE HR Consulting is a specialized Human Resources consulting firm dedicated to human capital and talent management. We also offer expertise in website and mobile app development, providing comprehensive solutions to meet the unique needs of businesses. Built with WordPress, our platform delivers a professional, user-friendly experience, combining our HR expertise with cutting-edge digital tools to enhance business growth and operational efficiency.`,
    link: 'https://aehrconsulting.com'
  },
  {
    id: 16,
    image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2F82218716_2564418757137969_2436667335092731904_n.jpg?alt=media&token=ebb17103-878f-4927-a983-ac29f914307a',
    category: 'IOS APP',
    likes: 690,
    title: 'SAVOO',
    delay: 500,
    description: `For the international personal care brand Savoo, I developed native iOS and Android applications using Swift and Kotlin. These apps bring Savoo's "care for every moment" philosophy to life by offering users a seamless experience in exploring a wide range of cosmetics. A key feature of the apps is the integration of an AI-powered hair and makeup try-on camera, allowing users to virtually try products before purchasing. This project highlights my expertise in creating innovative, AI-driven mobile solutions that enhance user engagement in the beauty and personal care industry.`,
    link: 'https://savoocare.com',
    iosLink: 'https://apps.apple.com/us/app/savoo/id1480983320',
  },
  
  {
    id: 12,
    image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2Fvi%5Brent.png?alt=media&token=089b395f-b588-42a6-b857-3bb0593db4e6',
    category: 'WEBSITE',
    likes: 690,
    title: 'VIP RENT A CAR',
    delay: 500,
    description: `The VIP Car Rental website, crafted with WordPress, provides a premium platform for users to effortlessly book their dream luxury vehicles. With a focus on user experience, the site features advanced booking functionality, allowing customers to easily browse, select, and reserve high-end cars for any occasion. The website combines sleek design with intuitive navigation, ensuring that every user can enjoy a seamless car rental experience in Dubai.`,
    link: 'https://www.vipcarrental.ae'
  },
  {
    id: 3,
    image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2FScreenshot%202024-08-19%20at%202.59.00%E2%80%AFAM.png?alt=media&token=69789553-67f2-402d-96c0-35d6faae3a2f',
    category: 'WEBSITE',
    likes: 630,
    title: 'ASK BIZI',
    delay: 500,
    description: `AskBizi is a comprehensive platform designed to streamline business setup services for companies of all sizes. The app, built using Angular with Firebase, offers a wide range of features to assist with every aspect of the setup process, ensuring businesses can get started and thrive with ease. The integrated admin panel allows for efficient management of service requests, while Firebase handles real-time data and secure authentication. Automated email notifications keep clients informed at every step, providing a seamless and responsive user experience.`,
    link: 'https://askbizi.firebaseapp.com'
  },
  {
    id: 5,
    image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2Fdukan.png?alt=media&token=b8722703-691d-4376-ac79-505f492dfd53',
    category: 'MOBILE APPLICATION',
    likes: 280,
    title: 'DUKAN',
    delay: 300,
    description: `Dukan is your go-to grocery store, right inside your phone! This fully free app, built natively for iOS, offers a smooth and user-friendly shopping experience with no extra charges. Whether you prefer to pay with cash or debit, Dukan makes it easy. Simply download the app, choose your favorite grocery store, shop and order, and your groceries are delivered straight to your door. Enjoy the convenience of effortless grocery shopping with Dukan.`,
    iosLink: 'https://apps.apple.com/app/dukan-دكان-تك/id1473245492'
  },
  
  {
    id: 6,
    image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2Ffamoushome.png?alt=media&token=ea9bc6d8-768a-4944-b74a-128f4d752548',
    category: 'MOBILE APPLICATION',
    likes: 690,
    title: 'Famous Home',
    delay: 500,
    description: `The Famous Home app is a premier platform I developed for both Android and iOS using React Native, focusing on delivering a seamless shopping experience for home textiles, including beds, blankets, quilts, and luxurious bathroom accessories. By integrating the best libraries and tools, I ensured the app is not only intuitive and responsive but also performs exceptionally well across devices. This project showcases my ability to build high-quality, cross-platform mobile applications that enhance user engagement and streamline the shopping experience in the home goods industry.`,
    iosLink: 'https://apps.apple.com/app/famoushome/id1597067155'
  },
  {
    id: 17,
    image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2F82218716_2564418757137969_2436667335092731904_n.jpg?alt=media&token=ebb17103-878f-4927-a983-ac29f914307a',
    category: 'ANDROID APP',
    likes: 690,
    title: 'SAVOO IQ',
    delay: 500,
    description: `For the international personal care brand Savoo, I developed native iOS and Android applications using Swift and Kotlin. These apps bring Savoo's "care for every moment" philosophy to life by offering users a seamless experience in exploring a wide range of cosmetics. A key feature of the apps is the integration of an AI-powered hair and makeup try-on camera, allowing users to virtually try products before purchasing. This project highlights my expertise in creating innovative, AI-driven mobile solutions that enhance user engagement in the beauty and personal care industry.`,
    link: 'https://savoocare.com',
    androidLink: 'https://play.google.com/store/apps/details?id=com.makane.savooiraq&hl=en'
  },
  {
    id: 7,
    image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2FScreenshot%202024-08-19%20at%203.43.13%E2%80%AFAM%20copy.png?alt=media&token=a1a65f02-91a0-4f6c-b1a0-d5613a7c9186',
    category: 'MOBILE APPLICATION',
    likes: 690,
    title: 'ONE CARD GLOBAL',
    delay: 500,
    description: `The One app is a native iOS application I developed to connect customers and businesses through an innovative discount card system. Offering year-round benefits across Egypt, Saudi Arabia, Turkey, and Malaysia, the app provides users with immediate discounts on various products and services. Built with a focus on high performance and user experience, the app ensures seamless navigation and access to continuous savings, making it a valuable tool for enhancing shopping experiences and driving business growth.`,
    iosLink: 'https://apps.apple.com/app/one-card-global/id1496820235'
  },
  {
    id: 8,
    image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2FScreenshot%202024-08-19%20at%203.43.13%E2%80%AFAM%20copy%202.png?alt=media&token=6a72745d-5626-413f-9711-435c857d930e',
    category: 'MOBILE APPLICATION',
    likes: 690,
    title: 'EAT N JOY',
    delay: 500,
    description: `The Eat n Joy app is a native mobile application I developed using React Native for both Android and iOS platforms. The app brings the rich heritage of Syrian cuisine to the fingertips of users, allowing them to explore and order dishes inspired by Damascene chefs. Each dish in the app reflects the deep-rooted history and fresh ingredients that define our culinary tradition. My work focused on creating an intuitive, seamless experience that captures the essence of Eat n Joy—enabling users to engage with our story through the food they see, smell, and taste.`,
    iosLink: 'https://apps.apple.com/app/eatnjoy/id1494632382'
  },
  {
    id: 9,
    image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2Fbermuda.png?alt=media&token=80f73546-971d-4ccf-a675-2bc9b552cc62',
    category: 'MOBILE APPLICATION',
    likes: 690,
    title: 'BERMUDA SHOPPING',
    delay: 500,
    description: `The Bermuda app is a native iOS application I developed to provide users with a seamless shopping experience, offering a wide range of products that save both time and money. Built with a focus on convenience, the app allows users to easily browse, order, and enjoy free delivery within the capital. I integrated Stripe payment to ensure secure and smooth transactions, making the purchasing process effortless. The app’s intuitive interface, combined with reliable order tracking and customer support features, ensures a satisfying and efficient user experience, aligning with Bermuda's commitment to quality service and customer satisfaction.`,
    iosLink: 'https://apps.apple.com/app/bermuda-shopping/id1441280002'
  },
  {
    id: 10,
    image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2Fpacccc.png?alt=media&token=58bce17a-001b-4e76-8fc2-9308ab6582fb',
    category: 'MOBILE APPLICATION',
    likes: 690,
    title: 'PACCCC APP',
    delay: 500,
    description: `The Pacccc app is a native iOS and Android application I developed to offer users the convenience of sending and receiving anything with ease, or delivering parcels to earn extra money. Featuring real-time mapping and push notifications, the app ensures seamless user experiences with live tracking and instant updates. This project showcases my expertise in building high-performance, user-centric mobile applications.`,
    iosLink: 'https://apps.apple.com/app/pacccc/id1520407439'
  },
 
  
//   {
//     id: 13,
//     image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2Fvi%5Brent.png?alt=media&token=089b395f-b588-42a6-b857-3bb0593db4e6',
//     category: 'WEBSITE',
//     likes: 690,
//     title: 'VIP RENT A CAR',
//     delay: 500,
//     description: `The VIP Car Rental website, crafted with WordPress, provides a premium platform for users to effortlessly book their dream luxury vehicles. With a focus on user experience, the site features advanced booking functionality, allowing customers to easily browse, select, and reserve high-end cars for any occasion. The website combines sleek design with intuitive navigation, ensuring that every user can enjoy a seamless car rental experience in Dubai.`,
//     link: 'https://www.vipcarrental.ae'
//   },

  {
    id: 15,
    image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2Fpromechanix.jpg?alt=media&token=2c75702e-2cd1-49d8-9fc3-399d55e2e53f',
    category: 'MOBILE APP',
    likes: 690,
    title: 'Pro Mechanix Uae',
    delay: 500,
    description: `For Pro Mechanix UAE, I developed a native iOS application using Swift, seamlessly integrating AWS services to ensure a scalable and reliable platform. The app connects users directly with expert mechanics, saving them time and money. My role in this project showcases my expertise in building robust, cloud-integrated mobile solutions tailored to the automotive industry.`,
    instLink: 'https://www.instagram.com/promechanixuae/',
    link: 'https://promechanix.com'
  },


  {
    id: 18,
    image: 'https://firebasestorage.googleapis.com/v0/b/nabilalsaadi-3d369.appspot.com/o/portfolio%2Fsealr.png?alt=media&token=db072c30-eaf1-487b-ab17-d8c2a8f7a4c6',
    category: 'Mobile APP',
    likes: 690,
    title: 'Sealr App',
    delay: 500,
    description: `For the Sealr app, I played a crucial role in fixing issues, deploying the application, and maintaining its smooth operation. Developed using React Native, Sealr enables users to securely and privately capture and share AI-verified images, videos, and audio recordings, even from remote locations. My work ensured the app's reliability and seamless integration with existing systems, allowing for real-time monitoring, verification, and reporting of events and activities.`,
    link: 'https://www.sealr.app'
  },

  
  
];
