import React, {useState} from 'react';
import { PORTFOLIO_ITEMS } from '../utils/constants';
import ModalComponent from './ModalComponent';

const PortfolioArea = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData,setModalData] = useState({})

  const handleOpenModal = (item) => {
    setModalData(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // const modalData = {
  //   imageSrc: 'assets/images/portfolio/portfolio-04.jpg',
  //   title: 'App Design Development',
  //   subtitle: 'Featured - Design',
  //   description1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate distinctio assumenda explicabo veniam temporibus eligendi.',
  //   description2: 'Consectetur adipisicing elit. Cupiditate distinctio assumenda. dolorum alias suscipit rerum maiores aliquam earum odit, nihil culpa quas iusto hic minus!'
  // };
  return (
    <>
    <div className="rn-portfolio-area rn-section-gap section-separator" id="portfolio">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <span className="subtitle">Visit my portfolio and keep your feedback</span>
              <h2 className="title">My Portfolio</h2>
            </div>
          </div>
        </div>

        <div className="row row--25 mt--10 mt_md--10 mt_sm--10">
          {PORTFOLIO_ITEMS.map((item) => (
           
               <div
                key={item.id}
                data-aos="fade-up"
                data-aos-delay={item.delay}
                data-aos-once="true"
                className="col-lg-6 col-xl-4 col-md-6 col-12 mt--50 mt_md--30 mt_sm--30"
              >
                 <a onClick={() => handleOpenModal(item)}>
                <div className="rn-portfolio" data-bs-toggle="modal" data-bs-target={`#exampleModalCenter${item.id}`}>
                  <div className="inner">
                    <div className="thumbnail">
                      <a href="#" style={{width: '100%'}} onClick={(e) => e.preventDefault()}>
                        <img src={item.image} className='portfolio-image' alt="Personal Portfolio Images" />
                      </a>
                    </div>
                    <div className="content">
                      <div className="category-info">
                        <div className="category-list">
                          <a href="#" onClick={(e) => e.preventDefault()}>{item.category}</a>
                        </div>
                        <div className="meta">
                          <span>
                            <a href="#" onClick={(e) => e.preventDefault()}>
                              <i className="feather-heart"></i>
                            </a>
                            {item.likes}
                          </span>
                        </div>
                      </div>
                      <h4 className="title">
                        <a href="#" onClick={(e) => e.preventDefault()}>
                          {item.title}
                          <i className="feather-arrow-up-right"></i>
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>
                </a>
              </div>
           
           
          ))}
        </div>
      </div>
    </div>
    {/* <button onClick={handleOpenModal} className="btn btn-primary">Open Modal</button> */}
      <ModalComponent
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        {...modalData}
      />
    </>
  );
};

export default PortfolioArea;
