import React, { useEffect } from 'react';

const IAMBanner = () => {
  useEffect(() => {
    const words = document.querySelectorAll('.cd-words-wrapper b');
    let index = 0;
    const changeWord = () => {
      words[index].classList.remove('is-visible');
      index = (index + 1) % words.length;
      words[index].classList.add('is-visible');
    };

    const interval = setInterval(changeWord, 2000); // Change word every 2 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div className="banner-inner">
      <h1 className="cd-headline clip">
        <span>I am a</span>
        <span className="cd-words-wrapper" style={{padding:'12px 10px 4px 10px'}}>
          <b className="is-visible">Software Engineer.</b>
          <b>Tech Lead.</b>
          <b>FullStack Developer.</b>
        </span>
      </h1>
    </div>
  );
};

export default IAMBanner;
