import React from 'react';
import bannerImage from '../assets/images/slider/banner-06.png';
import PortfolioArea from './PortfolioArea';
import ParticlesComponent from './ParticlesComponent';
import IAMBanner from './IAMBanner';
import ContactSection from './Contact';
import ServiceSection from './ServiceSection';
import Footer from './Footer';
import AboutSection from './About';

const MainPage = () => {
    
  return (
    <main className="page-wrapper-two">
      {/* Start Slider Area */}
      <section id="home" className="slider-style-5 rn-section-gap pb--110 align-items-center with-particles">
        {/* <div style={{ position: 'relative', height: '100vh', width: '100%' }}> */}
        <ParticlesComponent />
        {/* Your other content here */}
        {/* </div> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-inner">
                <div className="thumbnail gradient-border gradient-animation">
                  <img id="border" className="gradient-border" src={bannerImage} alt="Banner" />
                </div>
                <h1>NABIL ALSAADI</h1>
                {/* Type Headline Start */}
                <IAMBanner />
                {/* Type Headline End */}
                <div className="button-area">
                  <a className="rn-btn" href="#contacts"><span>CONTACT ME</span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Slider Area */}

      {/* Start Service Area */}
     
      {/* End Service Area */}
      
      <ServiceSection />
      <PortfolioArea />
      <AboutSection />
      <ContactSection />
      <Footer />
     
    </main>
  );
};

export default MainPage;
