import React, { useEffect } from 'react';


const AboutSection = () => {
  return (
    <div className="rn-service-area rn-section-gap section-separator" id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div
              className="section-title text-center"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="100"
              data-aos-once="true"
            >
              <span className="subtitle">who is nabil</span>
              <h2 className="title">About Me</h2>
              <div style={{marginTop: '50px', textAlign: 'left'}}>
              <p>I'm <strong>Nabil Alsaadi</strong>, a Senior Software Engineer and Tech Lead with over a decade of experience in the tech industry. My passion lies in creating innovative solutions that drive success and bring ideas to life. Specializing in full-stack development and mobile app development, I leverage the latest technologies to build scalable and efficient applications.</p>
    
    <p>Throughout my career, I have:</p>
    <ul>
        <li>Led dynamic teams to deliver high-quality software across industries such as healthcare and e-commerce.</li>
        <li>Developed and deployed mobile apps using React Native, Swift, and Kotlin.</li>
        <li>Integrated cloud solutions with AWS to ensure scalability and efficiency.</li>
        <li>Worked extensively with Angular, Node.js, and WordPress to create robust web applications.</li>
        <li>Continuously adapted to new tools and frameworks, staying at the forefront of technology.</li>
    </ul>
    
    <p>Some of my key achievements include:</p>
    <ul>
        <li>Successfully launching <strong>BuddyBoass</strong>, a mobile app used by thousands of users worldwide.</li>
        <li>Driving a <strong>30%</strong> increase in performance for a major platform through code optimization and innovative solutions.</li>
        <li>Leading a cross-functional team to deliver a complex project on time and within budget.</li>
    </ul>
    
    <p>I'm always excited to take on new challenges and collaborate with like-minded professionals. If you're looking for a dedicated and skilled software engineer to bring your project to life, let's connect and create something extraordinary together.</p>

              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default AboutSection;
