// import React from 'react';
// import 'feather-icons'; // Make sure to import feather icons or replace them with your preferred icon library
// import { SERVICE_ID, TEMPLATE_ID, USER_ID, contactemail, contactphone, githubLink, instagramLink, linkedinLink } from '../utils/constants';
// import emailjs from 'emailjs-com';

// const ContactSection = () => {

//     const sendEmail = (e) => {
//         e.preventDefault();
    
//         emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
//           .then((result) => {
//               console.log(result.text);
//               alert("Message sent successfully!");
//           }, (error) => {
//               console.log(error.text);
//               alert("An error occurred, please try again.");
//           });
    
//           e.target.reset(); // Reset form after submission
//       };

//   return (
//     <div className="rn-contact-area rn-section-gap section-separator" id="contacts">
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-12">
//             <div className="section-title text-center">
//               <span className="subtitle">Contact</span>
//               <h2 className="title">Contact With Me</h2>
//             </div>
//           </div>
//         </div>

//         <div className="row mt--50 mt_md--40 mt_sm--40 mt-contact-sm">
//           <div className="col-lg-5">
//             <div className="contact-about-area">
//               <div className="thumbnail">
//                 <img src={require('../assets/images/contact/contact1.png')} alt="contact-img" />
//               </div>
//               <div className="title-area">
//                 <h4 className="title">Nabil Alsaadi</h4>
//                 <span>Senior Software Engineer - Tech Lead</span>
//               </div>
//               <div className="description">
//                 <p>I am available for freelance work. Feel free to connect with me via email or phone.</p>
//                 <span className="phone">Phone: <a href={`tel:${contactphone}`}>{contactphone}</a></span>
//                 <span className="mail">Email: <a href={`mailto:${contactemail}`}>{contactemail}</a></span>
//               </div>
//               <div className="social-area">
//                 <div className="name">CONNECT WITH ME</div>
//                 <div className="social-icone">
//                   <a href={githubLink}><i data-feather="github"></i></a>
//                   <a href={linkedinLink}><i data-feather="linkedin"></i></a>
//                   <a href={instagramLink}><i data-feather="instagram"></i></a>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div data-aos-delay="600" className="col-lg-7 contact-input">
//             <div className="contact-form-wrapper">
//               <div className="introduce">
//                 <form className="rnt-contact-form rwt-dynamic-form row" id="contact-form" onSubmit={sendEmail}>
//                   <div className="col-lg-6">
//                     <div className="form-group">
//                       <label htmlFor="contact-name">Your Name</label>
//                       <input className="form-control form-control-lg" name="contact-name" id="contact-name" type="text" />
//                     </div>
//                   </div>

//                   <div className="col-lg-6">
//                     <div className="form-group">
//                       <label htmlFor="contact-phone">Phone Number</label>
//                       <input className="form-control" name="contact-phone" id="contact-phone" type="text" />
//                     </div>
//                   </div>

//                   <div className="col-lg-12">
//                     <div className="form-group">
//                       <label htmlFor="contact-email">Email</label>
//                       <input className="form-control form-control-sm" id="contact-email" name="contact-email" type="email" />
//                     </div>
//                   </div>

//                   <div className="col-lg-12">
//                     <div className="form-group">
//                       <label htmlFor="subject">Subject</label>
//                       <input className="form-control form-control-sm" id="subject" name="subject" type="text" />
//                     </div>
//                   </div>

//                   <div className="col-lg-12">
//                     <div className="form-group">
//                       <label htmlFor="contact-message">Your Message</label>
//                       <textarea name="contact-message" id="contact-message" cols="30" rows="10"></textarea>
//                     </div>
//                   </div>

//                   <div className="col-lg-12">
//                     <button name="submit" type="submit" id="submit" className="rn-btn">
//                       <span>SEND MESSAGE</span>
//                       <i data-feather="arrow-right"></i>
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ContactSection;


import React, { useState } from 'react';
import 'feather-icons';
import emailjs from 'emailjs-com';
import { SERVICE_ID, TEMPLATE_ID, USER_ID, contactemail, contactphone, githubLink, instagramLink, linkedinLink, whatsappLink } from '../utils/constants';
import { FaWhatsapp } from 'react-icons/fa';

const ContactSection = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        subject: '',
        message: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!formData.name) {
            formErrors.name = "Name is required";
            isValid = false;
        }

        if (!formData.email) {
            formErrors.email = "Email is required";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = "Email address is invalid";
            isValid = false;
        }

        if (!formData.message) {
            formErrors.message = "Message is required";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const sendEmail = (e) => {
        e.preventDefault();

        if (validateForm()) {
            emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
                .then((result) => {
                    console.log(result.text);
                    alert("Message sent successfully!");
                }, (error) => {
                    console.log(error.text);
                    alert("An error occurred, please try again.");
                });

            e.target.reset(); // Reset form after submission
            setFormData({ name: '', phone: '', email: '', subject: '', message: '' }); // Clear form data in state
            setErrors({}); // Clear errors
        }
    };

    return (
        <div className="rn-contact-area rn-section-gap section-separator" id="contacts">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center">
                            <span className="subtitle">Contact</span>
                            <h2 className="title">Contact With Me</h2>
                        </div>
                    </div>
                </div>

                <div className="row mt--50 mt_md--40 mt_sm--40 mt-contact-sm">
                    <div className="col-lg-5">
                        <div className="contact-about-area">
                            <div className="thumbnail">
                                <img src={require('../assets/images/contact/contact1.png')} alt="contact-img" />
                            </div>
                            <div className="title-area">
                                <h4 className="title">Nabil Alsaadi</h4>
                                <span>Senior Software Engineer - Tech Lead</span>
                            </div>
                            <div className="description">
                                <p>I am available for freelance work. Feel free to connect with me via email or phone.</p>
                                <span className="phone">Phone: <a href={`tel:${contactphone}`}>{contactphone}</a></span>
                                <span className="mail">Email: <a href={`mailto:${contactemail}`}>{contactemail}</a></span>
                            </div>
                            <div className="social-area">
                                <div className="name">CONNECT WITH ME</div>
                                <div className="social-icone">
                                    <a href={githubLink}><i data-feather="github"></i></a>
                                    <a href={linkedinLink}><i data-feather="linkedin"></i></a>
                                    <a href={instagramLink}><i data-feather="instagram"></i></a>
                                    <a href={whatsappLink}><FaWhatsapp size={24} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-aos-delay="600" className="col-lg-7 contact-input">
                        <div className="contact-form-wrapper">
                            <div className="introduce">
                                <form className="rnt-contact-form rwt-dynamic-form row" id="contact-form" onSubmit={sendEmail}>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="contact-name">Your Name</label>
                                            <input className="form-control form-control-lg" name="name" id="contact-name" type="text" value={formData.name} onChange={handleChange} />
                                            {errors.name && <p className="error-text">{errors.name}</p>}
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="contact-phone">Phone Number</label>
                                            <input className="form-control" name="phone" id="contact-phone" type="text" value={formData.phone} onChange={handleChange} />
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="contact-email">Email</label>
                                            <input className="form-control form-control-sm" id="contact-email" name="email" type="email" value={formData.email} onChange={handleChange} />
                                            {errors.email && <p className="error-text">{errors.email}</p>}
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="subject">Subject</label>
                                            <input className="form-control form-control-sm" id="subject" name="subject" type="text" value={formData.subject} onChange={handleChange} />
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="contact-message">Your Message</label>
                                            <textarea name="message" id="contact-message" cols="30" rows="10" value={formData.message} onChange={handleChange}></textarea>
                                            {errors.message && <p className="error-text">{errors.message}</p>}
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <button name="submit" type="submit" id="submit" className="rn-btn">
                                            <span>SEND MESSAGE</span>
                                            <i data-feather="arrow-right"></i>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactSection;
