/* eslint-disable */
import React, { useEffect } from 'react';
// import { loadFull } from "tsparticles";
import $ from 'jquery';
import AOS from 'aos';
import feather from 'feather-icons';
import WOW from 'wowjs';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel';


window.$ = $;
window.jQuery = $;

const GlobalScripts = () => {
  useEffect(() => {

    import('../assets/js/vendor/jquery-one-page-nav.js').then(() => {
        // Initialize the onePageNav plugin
        $('.onepagenav').onePageNav({
          currentClass: 'current',
          changeHash: true,
          scrollSpeed: 500,
          scrollThreshold: 0.2,
          filter: ':not(.external)',
          easing: 'swing',
          scrollChange: function ($currentListItem) {
            console.log(this);
          },
        });
      }).catch(error => console.error('Error loading one-page-nav:', error));

    // Initialize Feather icons globally
    feather.replace();

    // Initialize WOW.js for animations globally
    new WOW.WOW().init();

    // Initialize AOS for animations globally
    AOS.init();

    // const initializeParticles = async () => {
    //     // Load the full tsparticles engine
    //     const particlesInit = await loadFull();
  
    //     // Initialize the particles with the provided configuration
    //     particlesInit("particles-js", {
    //       particles: {
    //         number: {
    //           value: 20,
    //           density: {
    //             enable: true,
    //             value_area: 800,
    //           },
    //         },
    //         color: {
    //           value: ['#ffffff'],
    //         },
    //         shape: {
    //           type: 'circle',
    //           stroke: {
    //             width: 0,
    //             color: '#000000',
    //           },
    //           polygon: {
    //             nb_sides: 4,
    //           },
    //         },
    //         opacity: {
    //           value: 0.8,
    //           random: true,
    //         },
    //         size: {
    //           value: 4,
    //           random: true,
    //         },
    //         move: {
    //           enable: true,
    //           speed: 6,
    //         },
    //       },
    //       interactivity: {
    //         detect_on: 'canvas',
    //         events: {
    //           onhover: {
    //             enable: true,
    //             mode: 'repulse',
    //           },
    //           onclick: {
    //             enable: true,
    //             mode: 'push',
    //           },
    //         },
    //         modes: {
    //           repulse: {
    //             distance: 200,
    //           },
    //           push: {
    //             particles_nb: 4,
    //           },
    //         },
    //       },
    //       retina_detect: true,
    //     });
    //   };
  
  
    //   initializeParticles();
    // Back to Top functionality
    const scrollTop = $('.backto-top');
    $(window).scroll(function () {
      const topPos = $(this).scrollTop();
      if (topPos > 100) {
        $(scrollTop).css('opacity', '1');
      } else {
        $(scrollTop).css('opacity', '0');
      }
    });
    $(scrollTop).on('click', function () {
      $('html, body').animate({
        scrollTop: 0,
      }, 500);
      return false;
    });

    // Sticky Header
    $(window).scroll(function () {
      if ($(this).scrollTop() > 250) {
        $('.header--sticky').addClass('sticky');
      } else {
        $('.header--sticky').removeClass('sticky');
      }
    });

    // Smooth Scroll
    $(document).on('click', '.smoth-animation', function (event) {
      event.preventDefault();
      $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top - 50,
      }, 300);
    });

    $(document).on('click', '.smoth-animation-two', function (event) {
      event.preventDefault();
      $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top,
      }, 300);
    });

    // Video Activation
    $('#play-video, .play-video').on('click', function (e) {
      e.preventDefault();
      $('#video-overlay, .video-overlay').addClass('open');
      $("#video-overlay, .video-overlay").append('<iframe width="80%" height="80%" src="https://www.youtube.com/embed/7e90gBu4pas" frameborder="0" allowfullscreen></iframe>');
    });

    $('.video-overlay, .video-overlay-close').on('click', function (e) {
      e.preventDefault();
      closeVideo();
    });

    $(document).keyup(function (e) {
      if (e.keyCode === 27) {
        closeVideo();
      }
    });

    function closeVideo() {
      $('.video-overlay.open').removeClass('open').find('iframe').remove();
    }

    // Mobile Menu Activation
    $('.humberger-menu').on('click', function (e) {
        e.preventDefault();
        $('.popup-mobile-menu').addClass('menu-open');
        $('html').css({ overflow: 'hidden' });
      });
  
      $('.close-menu-activation, .popup-mobile-menu .primary-menu .nav-item a').on('click', function (e) {
        e.preventDefault();
        $('.popup-mobile-menu').removeClass('menu-open');
        $('.popup-mobile-menu .has-droupdown > a').removeClass('open').siblings('.submenu').removeClass('active').slideUp('400');
        $('html').css({ overflow: '' });
      });
  
      $('.popup-mobile-menu').on('click', function (e) {
        if (e.target === this) {
          $('.popup-mobile-menu').removeClass('menu-open');
          $('html').css({ overflow: '' });
        }
      });
  
      $('.popup-mobile-menu .has-droupdown > a').on('click', function (e) {
        e.preventDefault();
        $(this).siblings('.submenu').toggleClass('active').slideToggle('400');
        $(this).toggleClass('open');
        $('html').css({ overflow: '' });
      });
  
      // Activate Slick sliders (Testimonial, Portfolio, Blog, etc.)
      $('.testimonial-activation').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        adaptiveHeight: true,
        cssEase: 'linear',
        prevArrow: '<button class="slide-arrow prev-arrow"><i class="feather-arrow-left"></i></button>',
        nextArrow: '<button class="slide-arrow next-arrow"><i class="feather-arrow-right"></i></button>',
      });
  
      $('.portfolio-slick-activation').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        cssEase: 'linear',
        adaptiveHeight: true,
        prevArrow: '<button class="slide-arrow prev-arrow"><i class="feather-arrow-left"></i></button>',
        nextArrow: '<button class="slide-arrow next-arrow"><i class="feather-arrow-right"></i></button>',
        responsive: [
          {
            breakpoint: 1124,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 868,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              arrows: false,
            },
          },
        ],
      });
  
      // Demo Activation
      $('.rn-right-demo').on('click', function () {
        $('.demo-modal-area').addClass('open');
      });
  
      $('.demo-close-btn').on('click', function () {
        $('.demo-modal-area').removeClass('open');
      });
  
    //   One Page Navigation
    //   $('.onepagenav').onePageNav({
    //     currentClass: 'current',
    //     changeHash: true,
    //     scrollSpeed: 500,
    //     scrollThreshold: 0.2,
    //     filter: ':not(.external)',
    //     easing: 'swing',
    //     scrollChange: function($currentListItem) {
    //       console.log(this);
    //     }
    //   });
  

  }, []);

  return null;
};

export default GlobalScripts;
