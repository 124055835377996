import logo from './logo.svg';
import './App.css';
import CustomHeader from './components/CustomHeader';
import MobileHeader from './components/MobileHeader';
import PopupMobileMenu from './components/PopupMobileMenu';
import MainPage from './components/MainPage';
import { useEffect } from 'react';
import GlobalScripts from './components/GlobalScripts';
import ContactSection from './components/Contact';

function App() {
  useEffect(() => {
    // Apply class and data attributes to the body element
    document.body.className = 'template-color-1 home-sticky spybody';
    document.body.setAttribute('data-spy', 'scroll');
    document.body.setAttribute('data-target', '.navbar-example2');
    document.body.setAttribute('data-offset', '150');

    // Cleanup function to remove these attributes when the component unmounts
    return () => {
      document.body.className = '';
      document.body.removeAttribute('data-spy');
      document.body.removeAttribute('data-target');
      document.body.removeAttribute('data-offset');
    };
  }, []);
  return (
    <>
      <GlobalScripts />
      <CustomHeader />
      <MobileHeader />
      <PopupMobileMenu />
      <MainPage />
     
      {/* Other components */}
    </>
  );
}

export default App;
