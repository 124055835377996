// import React, { useEffect } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import feather from 'feather-icons';
// import { X, ThumbsUp, ChevronRight } from 'react-feather';
// import { FaApple, FaAndroid, FaGlobe } from 'react-icons/fa';

// const ModalComponent = ({ isOpen, onClose, image, title, category, description, link, iosLink, androidLink }) => {
//     useEffect(() => {
//         feather.replace(); // Initialize Feather Icons
//       }, []);
      
//   if (!isOpen) return null;
  
//   return (
//     <div className={`modal fade show`} tabIndex="-1" role="dialog" style={{ display: 'block' }} aria-hidden="true">
//       <div className="modal-dialog modal-dialog-centered" role="document">
//         <div className="modal-content">
//           <div className="modal-header">
//             <button type="button" className="close" onClick={onClose} aria-label="Close">
//               <span aria-hidden="true"><X /></span>
//             </button>
//           </div>
//           <div className="modal-body">
//             <div className="row align-items-center">
//               <div className="col-lg-6">
//                 <div className="portfolio-popup-thumbnail">
//                   <div className="image">
//                     <img className="w-100" src={image} alt="slide" />
//                   </div>
//                 </div>
//               </div>

//               <div className="col-lg-6">
//                 <div className="text-content">
//                   <h3>
//                     <span>{category}</span> {title}
//                   </h3>
//                   <p className="mb--30">{description}</p>
//                   {/* <p>{description2}</p> */}
//                   <div className="button-group mt--20">
//                     <a href="#" className="rn-btn thumbs-icon">
//                       <span style={{marginRight: '6px'}}>LIKE THIS</span>
//                       <ThumbsUp />
//                     </a>
//                     {link && (
//                         <a href={link} rel="noopener noreferrer" className="rn-btn">
//                         <FaGlobe style={{ marginRight: '6px' }} />
//                         <span>Website</span>
//                         </a>
//                     )}
//                     {iosLink && (
//                         <a href={iosLink} rel="noopener noreferrer" className="rn-btn" style={{ marginRight: '6px' }}>
//                         <FaApple style={{ marginRight: '6px' }} />
//                         <span>Download on iOS</span>
//                         </a>
//                     )}
//                     {androidLink && (
//                         <a href={androidLink} rel="noopener noreferrer" className="rn-btn">
//                         <FaAndroid style={{ marginRight: '6px' }} />
//                         <span>Download on Android</span>
//                         </a>
//                     )}
//                   </div>
//                 </div>
//                 {/* End of .text-content */}
//               </div>
//             </div>
//             {/* End of .row Body */}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ModalComponent;


import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import feather from 'feather-icons';
import { X, ThumbsUp } from 'react-feather';
import { FaApple, FaAndroid, FaGlobe, FaSearchPlus } from 'react-icons/fa';


const ModalComponent = ({ isOpen, onClose, image, title, category, description, link, iosLink, androidLink }) => {
  const [isZoomed, setIsZoomed] = useState(false);

  useEffect(() => {
    feather.replace(); // Initialize Feather Icons
  }, []);

  if (!isOpen) return null;

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  return (
    <div className={`modal fade show`} tabIndex="-1" role="dialog" style={{ display: 'block' }} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" onClick={onClose} aria-label="Close">
              <span aria-hidden="true"><X /></span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="portfolio-popup-thumbnail" onClick={toggleZoom}>
                  <div className="image">
                    <img className="w-100" src={image} alt="slide" />
                    <div className="zoom-icon">
                      <FaSearchPlus />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="text-content">
                  <h3>
                    <span>{category}</span> {title}
                  </h3>
                  <p className="mb--30">{description}</p>
                  <div className="button-group mt--20">
                    <a href="#" className="rn-btn thumbs-icon">
                      <span style={{ marginRight: '6px' }}>LIKE THIS</span>
                      <ThumbsUp />
                    </a>
                    {link && (
                      <a href={link} rel="noopener noreferrer" className="rn-btn">
                        <FaGlobe style={{ marginRight: '6px' }} />
                        <span>Website</span>
                      </a>
                    )}
                    {iosLink && (
                      <a href={iosLink} rel="noopener noreferrer" className="rn-btn" style={{ marginRight: '6px' }}>
                        <FaApple style={{ marginRight: '6px' }} />
                        <span>Download on iOS</span>
                      </a>
                    )}
                    {androidLink && (
                      <a href={androidLink} rel="noopener noreferrer" className="rn-btn">
                        <FaAndroid style={{ marginRight: '6px' }} />
                        <span>Download on Android</span>
                      </a>
                    )}
                  </div>
                </div>
                {/* End of .text-content */}
              </div>
            </div>
            {/* End of .row Body */}
          </div>
        </div>
      </div>

      {/* Fullscreen Zoomed Image */}
      {isZoomed && (
        <div className="zoom-overlay" onClick={toggleZoom}>
          <img className="zoomed-image" src={image} alt="Zoomed" />
        </div>
      )}
    </div>
  );
};

export default ModalComponent;
