import React, { useEffect } from 'react';
import logo from '../assets/images/logo/logo-06.png'
import feather from 'feather-icons';

const MobileHeader = () => {
    useEffect(() => {
        feather.replace(); // Initialize Feather Icons
      }, []);
  return (
    <div className="header-style-2 d-block d-xl-none">
      <div className="row align-items-center">
        <div className="col-6">
          <div className="logo">
            <a href="index.html">
              <img src={logo} alt="Logo" />
            </a>
          </div>
        </div>
        <div className="col-6">
          <div className="header-right text-end">
            <div className="hamberger-menu" style={{ cursor: 'pointer' }}>
              <i data-feather="menu" id="menuBtn" className="feather-menu humberger-menu"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
