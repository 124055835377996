import React from 'react';

const Footer = () => {
  return (
    <div className="rn-footer-area rn-section-gap section-separator">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="footer-area text-center">

              <div className="logo">
                <a href="index.html">
                  <img src={require('../assets/images/logo/logo-06.png')} alt="logo" />
                </a>
              </div>

              <p className="description mt--30">
                © 2024. All rights reserved by{' '}
                <a target="_blank" rel="noopener noreferrer" href="https://nabilalsaadi.com">
                  Nabil Alsaadi.
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
