// import React, { useCallback } from 'react';
// import Particles from 'react-tsparticles';
// import { loadFull } from 'tsparticles';

// const ParticlesComponent = () => {
//   const particlesInit = useCallback(async (engine) => {
//     console.log(engine);
//     await loadFull(engine);
//   }, []);

//   return (
//     <Particles
//       id="tsparticles"
//       init={particlesInit}
//       options={{
//         fpsLimit: 60,
//         particles: {
//           number: {
//             value: 50,
//             density: {
//               enable: true,
//               value_area: 800,
//             },
//           },
//           color: {
//             value: "#ffffff",
//           },
//           shape: {
//             type: "circle",
//           },
//           opacity: {
//             value: 0.5,
//             random: false,
//           },
//           size: {
//             value: 3,
//             random: true,
//           },
//           move: {
//             enable: true,
//             speed: 1,
//             direction: "none",
//             random: false,
//             straight: false,
//             outMode: "out",
//             attract: {
//               enable: false,
//             },
//           },
//         },
//         interactivity: {
//           events: {
//             onHover: {
//               enable: true,
//               mode: "repulse",
//             },
//             onClick: {
//               enable: true,
//               mode: "push",
//             },
//             resize: true,
//           },
//           modes: {
//             repulse: {
//               distance: 200,
//             },
//             push: {
//               particles_nb: 4,
//             },
//           },
//         },
//         detectRetina: true,
//       }}
//       style={{
//         position: 'absolute',
//         top: 0,
//         left: 0,
//         width: '100%',
//         height: '100%',
//         zIndex: -1,
//       }}
//     />
//   );
// };

// export default ParticlesComponent;

import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
// import { loadAll } from "@tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.

const ParticlesComponent = () => {
  const [init, setInit] = useState(false);

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const options = useMemo(
    () => ({
    //   background: {
    //     color: {
    //       value: "#0d47a1",
    //     },
    //   },
    //   fpsLimit: 120,
    //   interactivity: {
    //     events: {
    //       onClick: {
    //         enable: true,
    //         mode: "push",
    //       },
    //       onHover: {
    //         enable: true,
    //         mode: "repulse",
    //       },
    //     },
    //     modes: {
    //       push: {
    //         quantity: 4,
    //       },
    //       repulse: {
    //         distance: 200,
    //         duration: 0.4,
    //       },
    //     },
    //   },
    //   particles: {
    //     color: {
    //       value: "#ffffff",
    //     },
    //     links: {
    //       color: "#ffffff",
    //       distance: 150,
    //       enable: true,
    //       opacity: 0.5,
    //       width: 1,
    //     },
    //     move: {
    //       direction: "none",
    //       enable: true,
    //       outModes: {
    //         default: "bounce",
    //       },
    //       random: false,
    //       speed: 6,
    //       straight: false,
    //     },
    //     number: {
    //       density: {
    //         enable: true,
    //       },
    //       value: 80,
    //     },
    //     opacity: {
    //       value: 0.5,
    //     },
    //     shape: {
    //       type: "circle",
    //     },
    //     size: {
    //       value: { min: 1, max: 5 },
    //     },
    //   },
    //   detectRetina: true,
    fpsLimit: 60,
        particles: {
          number: {
            value: 50,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          links: {
            color: "#ffffff",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
         },
          color: {
            value: "#ffffff",
          },
          shape: {
            type: "circle",
          },
          opacity: {
            value: 0.5,
            random: false,
          },
          size: {
            value: 3,
            random: true,
          },
        //   move: {
        //     enable: true,
        //     speed: 1,
        //     direction: "none",
        //     random: false,
        //     straight: false,
        //     outMode: "out",
        //     attract: {
        //       enable: false,
        //     },
        //   },
        move: {
            direction: "none",
            enable: true,
            outModes: {
            default: "bounce",
            },
            random: false,
            speed: 1,
            straight: false,
        },
        },
        interactivity: {
          events: {
            onHover: {
              enable: true,
              mode: "repulse",
            },
            onClick: {
              enable: false,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            repulse: {
              distance: 200,
            },
            push: {
              particles_nb: 4,
            },
          },
        },
        detectRetina: true,
    }),
    [],
  );

  if (init) {
    return (
      <Particles
        id="tsparticles"
        particlesLoaded={particlesLoaded}
        options={options}
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: -1,
          }}
      />
    );
  }

  return <></>;
};

export default ParticlesComponent;